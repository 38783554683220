<template >
    <div>

        <b-row class="mt-n1">
            <!-- LYSIMETER start -->
            <b-col cols="12">
                <b-card
                    class="small font-weight-bolder h-100"
                >
                        <b-row>
                            <b-col cols="12">
                                <b-row class="my-1">
                                    <b-col cols="3">
                                        <b-form-file
                                        v-model="file"
                                        size="sm"
                                        placeholder="Uploade File ..."
                                        drop-placeholder="Drop file ."
                                        />
                                    </b-col>
                                    <b-col cols="1 ml-n2">
                                        <b-button 
                                        size="sm"
                                        variant="gradient-success">
                                        Proses
                                        </b-button>
                                    </b-col>
                                </b-row>

                                <b-row class="my-1">
                                    <b-col cols="4">
                                    <b-form-group
                                        label-cols="4"
                                        label-cols-lg="4"
                                        label="Result :"
                                        label-class="text-primary"
                                        label-for="input-default"
                                        >
                                        <b-form-input
                                            id="input-default"
                                            size="sm"
                                            placeholder="Hasil"
                                            class="bg-light-secondary"
                                        />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                </b-card>
            </b-col>
            <!-- LYSIMETER end -->
        </b-row>
    </div>
</template>
<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
    BCard,
    BCardHeader,
    BContainer,
    BRow,
    BCol,
    BFormSelect,
    BForm,
    BButton,
    BFormGroup,
    BCollapse,
    BFormDatepicker,
    BFormInput,
    BFormFile,
} from "bootstrap-vue";
export default {
    components: {
        BCardActions,
        BCard,
        BCardHeader,
        BContainer,
        BRow,
        BCol,
        BFormSelect,
        BForm,
        BButton,
        BFormGroup,
        BCollapse,
        BFormDatepicker,
        BFormInput,
        BFormFile
    },
    props :['data_input'],
}
</script>
<style scoped>

.label-entry1 {
  text-align: center;
  color: #5e5873;
  width: 100%;
  border: 0px solid #B5B5C3;
  border-radius: 3px;
  padding: 4px;
}

</style>