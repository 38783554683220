<template>
  <div>
    <!-- Filter start -->
    <div class="row">
      <div class="col-xl-12">
        <b-card-actions title="Filter" action-collapse>
          <b-row>
            <b-col sm="4">
              <b-form-group
                label-cols="12"
                label-cols-lg="3"
                label-size="sm"
                label="Station"
                label-for="select-station"
              >
                <b-form-select
                  v-model="t_station"
                  class=""
                  size="sm"
                  id="select-station"
                >
                  <option :value="option">Pilih Stasiun</option>
                  <option value="a">Stasiun Klimatologi Kelas I Bandung</option>
                  <option value="b">
                    Stasiun Klimatologi Kelas I Semarang
                  </option>
                  <option value="c">
                    Stasiun Klimatologi Kelas II Sicincin Padang
                  </option>
                  <option value="d">
                    Stasiun Klimatologi Kelas I Semarang
                  </option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <b-form-group
                label-cols="4"
                label-cols-lg="5"
                label-size="sm"
                label="PIC/Observer"
                label-for="input-default"
              >
                <b-form-input id="input-default" placeholder="PIC" size="sm" />
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group
                label-cols="12"
                label-cols-lg="5"
                label-size="sm"
                label="WMO ID"
                label-for="select-wmo"
              >
                <b-form-select
                  v-model="t_wmoid"
                  class=""
                  id="select-wmo"
                  size="sm"
                >
                  <option :value="option">Pilih WMO ID</option>
                  <option value="a">12344</option>
                  <option value="b">89776</option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col sm="3">
              <b-form inline id="select-form">
                <b-form-select v-model="selectedForm" class="mr-1 col-7" size="sm">
                  <option disabled value="">Pilih Form</option>
                  <option value="1">Petir</option>
                  <option value="2">Percepatan Tanah</option>
                  <option value="3">Magnet</option>
                  <option value="4">Waveform Gempa</option>
                </b-form-select>
                <b-button
                  v-on:click="onFetch"
                  variant="primary"
                  class="ml-sm-6"
                  size="sm"
                  >Fetch</b-button
                >
              </b-form>
            </b-col>
          </b-row>
        </b-card-actions>
      </div>
    </div>
    <!-- Filter end -->

    <petir v-if="choosenForm == '1'"> </petir>
  </div>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BCard,
  BCardHeader,
  BContainer,
  BRow,
  BCol,
  BFormSelect,
  BForm,
  BButton,
  BFormGroup,
  BCollapse,
  BFormDatepicker,
  BFormInput,
} from "bootstrap-vue";
// import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Petir from "./geofisika/Petir.vue";

export default {
  components: {
    BCardActions,
    BCard,
    BCardHeader,
    BContainer,
    BRow,
    BCol,
    BFormSelect,
    BForm,
    BButton,
    BFormGroup,
    BCollapse,
    BFormDatepicker,
    Petir,
    BFormInput,
  },
  data: function () {
    return {
      selectedForm: "",
      choosenForm: "",
      data_input: {
        i_date: "",
        i_time: "",
        i_wmoid: "",
        i_station: "",
      },

      option: "",
      t_date: "",
      t_time: "",
      t_wmoid: "a",
      t_station: "a",
    };
  },
  methods: {
    onFetch() {
      Swal.fire({
        title: 'Do you want to save the changes?',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Save',
        denyButtonText: `Don't save`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          Swal.fire('Saved!', '', 'success')
        } else if (result.isDenied) {
          Swal.fire('Changes are not saved', '', 'info')
        }
      })
      this.choosenForm = this.selectedForm;
    },
  },
  mounted() {
    // this.$store.dispatch(SET_BREADCRUMB, [
    //   { title: "Klimatologi", route: "fdihmain" },
    //   { title: "FDIH" }
    // ]);
  },
};
</script>

<style scoped>
.reduce-margin-row {
  margin-bottom: -1.75rem !important;
}

.icon-right {
  float: right;
}
</style>

<style>
.nav-pills {
  margin-bottom: 0rem;
}
.container {
  padding-right: 0rem;
  padding-left: 0rem;
  margin-right: 0rem;
  margin-left: 0rem;
}
</style>
